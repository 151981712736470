.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10rem 5rem;
}

.banner {
    text-align: center;
    padding-bottom: 2rem;
    font-size: 3.5rem !important;
}

.banner-subtitle {
    text-align: center;
    font-size: 16px;
    margin-bottom: 5rem;
}

.review {
    text-align: center;
    padding: 2rem 10rem;
}



.hero-title,
.hero-subtitle {
    letter-spacing: .2rem;
}

.hero-title {
    font-size: 30px;
}

.hero-subtitle {
    font-size: 24px;
}

.sub-header {
    border: 5px double #fff;
    margin: 15rem 5rem;
}

.grid {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 0 2rem;
    padding-top: 2rem;
    /* background-color: var(--silver); */
}

.class-card {
    margin: 2rem;
}

.background {
    background-color: var(--darkest);
    color: white;
    font: inherit;
    font-size: 2rem;
    text-align: center;
    padding: 1rem;
}

.classesTitle {
    font-size: 25px;
    display: flex;
    justify-content: center;
    /* background-color: var(--silver); */
    /* margin: 2rem 2rem 0;
    padding-top: 2rem; */
}

.aboutTitle {
    font-size: 25px;
    display: flex;
    justify-content: center;
    margin-top: 15rem;
}


@media screen and (max-width:1145px) {}




@media screen and (max-width: 770px) {

    .banner {
        font-size: 2.5rem !important;
        padding: 0 5rem 2rem 5rem;
        font-weight: bolder !important;
    }

    .hero {
        margin: 6rem 5rem;
    }


    .review {
        padding: 2rem 3rem;
    }

    .hide {
        display: none;
    }

    .hero-title,
    .hero-subtitle {
        font-size: 16px;
    }

    .sub-header {
        border: 3px double #fff;
        margin: 2rem;
    }
}


@media screen and (max-width: 550px) {
    .banner {
        font-size: 2.3rem !important;
    }

    .hero {
        margin: 6rem 5rem;
    }
}

@media screen and (max-width:490px) {
    .hero {
        margin: 6rem 3rem;
    }
}

@media screen and (max-width:450px) {
    .hero {
        margin: 6rem 2rem;
    }
}

@media screen and (max-width:405px) {
    .hero {
        margin: 6rem 1rem;
    }
}

@media screen and (max-width:327px) {
    .hero {
        margin: 6rem .5rem;
    }

    .banner {
        font-size: 2rem !important;
    }

    .review {
        font-size: 1.5rem !important;
    }
}