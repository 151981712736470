:root {
  --jet: rgb(53, 53, 53);
  --light: rgb(255, 255, 255);
  --french-gray: rgb(210, 215, 223);
  --silver: rgb(189, 187, 176);
  --battleship-gray: rgb(138, 137, 124);
  --darkest: #191d1c;
  --highlight: #017d60;
  --royal-blue: rgb(57, 88, 181);
  --red: rgb(104, 15, 2);
  --dark-blue: rgb(5, 68, 158);
}

/* --------------------*/
/* Fonts               */
/* --------------------*/

/*  font-family: 'Fira Sans', sans-serif; */
/* font-family: 'Pinyon Script', cursive; */
/* --------------------*/
/* Root style          */
/* --------------------*/

html,
body {
  margin: 0;
  height: 100%;
}


html {
  font-size: 62.5%;
}


#root {
  font-family: 'Fira Sans', sans-serif;
  font-size: 62.5%;
  display: flex;
  flex-direction: column;
}



img {
  max-width: 100%;
  display: block;
}



/* --------------------*/
/* Scrollbar           */
/* --------------------*/

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(132deg, rgb(187, 36, 13) 27.00%, rgb(255, 255, 255) 45.00%, rgb(5, 68, 158) 69.00%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--jet);
}

/* --------------------*/
/* Utility Classes     */
/* --------------------*/

.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}