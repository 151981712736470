.about-container {
    margin: 5em 1em;
}

.card-container {
    margin: 0 auto;
}


.about-para {
    padding: 0 2rem;
    text-align: center;
}

.avatar {
    width: 10em;
    height: 8em;
    padding: 1rem;
}

.badges {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 5rem;
}

.uv {
    filter: brightness(0.8) contrast(1.9) saturate(1.4) opacity(0.71) sepia(0.26) grayscale(0.72);
}

.certs {
    text-align: center;
    margin-bottom: 5rem;
}




@media screen and (max-width: 1000px) {
    .card-container {
        margin: 0 5em;
    }
}

@media screen and (max-width: 865px) {}

@media screen and (max-width: 625px) {}

@media screen and (max-width: 552px) {
    .card-container {
        margin: 0 5em;
    }

    .avatar {
        /* margin: 2em 0; */
        width: 5em;
        height: 5em;
    }

}

@media screen and (max-width: 441px) {
    .about-container {
        margin: 5em 0;
    }

    .about-para {
        padding: 0;

    }

}

@media screen and (max-width: 373px) {
    .card-container {
        margin: 0 2em;
    }


}

/* @media screen and (max-width: 350px) {
    .about-para {

        text-align: center;
    }
} */