.desktop-nav-btn {
    color: var(--french-gray);
    text-decoration: none;
}

.contact-btn {
    padding: 1em;
}

.mobile-nav-btn,
.contact-links {
    color: var(--darkest);
    text-decoration: none;
    font-weight: 900;
}

.bizName {
    /* font-family: 'Pinyon Script', cursive; */
    text-decoration: none;
    color: inherit;
    font-size: 16px;
}

/* .mobileBizName {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
} */

@media screen and (max-width: 690px) {
    .mobileBizName {
        padding: 1em;
        text-wrap: wrap;
        text-align: center;
    }
}

@media screen and (max-width: 385px) {
    .disappear {
        display: none;
    }
}