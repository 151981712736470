.foot {
    margin-top: 5rem;
    border-top: 10px double var(--red);
}

.footer-text {
    text-align: center;
    margin-top: 3em;
    padding: 0 2rem;
    font-size: 1.5rem;
}